
import * as INVM_GETConstants from '../Constants/INVM_GETConstants';

const intialstate = {};



function GetStoreUserAccessAuditReducer(state = {
  StoreUserAccessAuditData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.StoreUserAccessAudit_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.StoreUserAccessAudit_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.StoreUserAccessAudit_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        StoreUserAccessAuditData: action.payload
      }

    }
    default: return state;
  }

}
function GetEmpUpdateTimeForExportCSVReducer(state = {
  GetEmpUpdateTimeForExportCSVData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.EmpUpdateTimeForExportCSV_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.EmpUpdateTimeForExportCSV_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.EmpUpdateTimeForExportCSV_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetEmpUpdateTimeForExportCSVData: action.payload
      }
    }
    default: return state;
  }
}

//Item Category Reducer
function GetItemCategoryReducer(state = {
  GetItemCategoryData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemCategory_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemCategory_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemCategory_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemCategoryData: action.payload
      }

    }
    default: return state;
  }

}
function GetCategoryNatureReducer(state = {
  GetCategoryNatureData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.CategoryNature_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.CategoryNature_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.CategoryNature_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetCategoryNatureData: action.payload
      }

    }
    default: return state;
  }

}
function GetVaccinationReducer(state = {
  GetVaccinationData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.Vaccination_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.Vaccination_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.Vaccination_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetVaccinationData: action.payload
      }

    }
    default: return state;
  }

}
function GetStoreCategoryReducer(state = {
  GetSearchStoreData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetSearchStoreCategory_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetSearchStoreCategory_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetSearchStoreCategory_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetSearchStoreData: action.payload
      }

    }
    default: return state;
  }

}

function GetSupplierCategoryReducer(state = {
  GetSearchSupplierData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetSearchSupplierCategory_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetSearchSupplierCategory_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetSearchSupplierCategory_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetSearchSupplierData: action.payload
      }

    }
    default: return state;
  }

}
//Status Master Reducer
function GetStatusByTypeReducer(state = {
  GetStatusByTypeData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.INVSTATUSBYTYPE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.INVSTATUSBYTYPE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.INVSTATUSBYTYPE_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetStatusByTypeData: action.payload
      }
    }
    default: return state;
  }

}

function GetStatusByTypeSpecial_IndentReducer(state = {
  GetStatusByTypeSpecial_IndentData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.STATUSBYTYPEINDENT_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.STATUSBYTYPEINDENT_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.STATUSBYTYPEINDENT_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetStatusByTypeSpecial_IndentData: action.payload
      }
    }
    default: return state;
  }

}

//Item Subcategory Reducer
function GetItemSubCategoryReducer(state = {
  GetItemSubCategoryData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemSubCategory_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemSubCategory_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemSubCategory_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemSubCategoryData: action.payload
      }

    }
    default: return state;
  }

}
//Item ChargeMaster Reducer
function GetChargeMasterListReducer(state = {
  GetChargeMasterListData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.Charges_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.Charges_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.Charges_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetChargeMasterListData: action.payload
      }

    }
    default: return state;
  }

}
function GetCIMSDataReducer(state = {
  GetCIMSData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetCIMSData_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetCIMSData_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetCIMSData_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetCIMSData: action.payload
      }

    }
    default: return state;
  }
}
function GetChargeSetupReducer(state = {
  GetChargeSetupData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ChargeSetup_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ChargeSetup_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ChargeSetup_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetChargeSetupData: action.payload
      }

    }
    default: return state;
  }

}
function GetChargesReducer(state = {
  GetChargesData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.Charges_GetCharges_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.Charges_GetCharges_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.Charges_GetCharges_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetChargesData: action.payload
      }

    }
    default: return state;
  }

}
function GetUnitMasterReducer(state = {
  GetUnitMasterData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.UnitMaster_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.UnitMaster_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.UnitMaster_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetUnitMasterData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemUnitMasterReducer(state = {
  GetItemUnitMasterData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemUnitMaster_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemUnitMaster_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemUnitMaster_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemUnitMasterData: action.payload
      }

    }
    default: return state;
  }

}
function GetHSNGroupReducer(state = {
  GetHSNGroupData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.HSNGroup_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.HSNGroup_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.HSNGroup_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetHSNGroupData: action.payload
      }

    }
    default: return state;
  }

}
function GetHSNCodeReducer(state = {
  GetHSNCodeData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.HSNCode_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.HSNCode_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.HSNCode_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetHSNCodeData: action.payload
      }

    }
    default: return state;
  }

}
//************KC********************
function GetItemMasterAuditFacilityMappingReducer(state = {
  GetItemMasterAuditFacilityMappingData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemMasterAuditFacilityMapping_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemMasterAuditFacilityMapping_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemMasterAuditFacilityMapping_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemMasterAuditFacilityMappingData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemMasterAuditControlledPriceReducer(state = {
  GetItemMasterAuditControlledPriceData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemMasterAuditControlledPrice_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemMasterAuditControlledPrice_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemMasterAuditControlledPrice_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemMasterAuditControlledPriceData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemMasterAuditItemAttributeReducer(state = {
  GetItemMasterAuditItemAttributeData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemMasterAuditItemAttribute_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemMasterAuditItemAttribute_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemMasterAuditItemAttribute_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemMasterAuditItemAttributeData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemMasterAuditDoseSettingsReducer(state = {
  GetItemMasterAuditDoseSettingsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemMasterAuditDoseSettings_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemMasterAuditDoseSettings_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemMasterAuditDoseSettings_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemMasterAuditDoseSettingsData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemMasterAuditFrequencyReducer(state = {
  GetItemMasterAuditFrequencyData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemMasterAuditFrequency_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemMasterAuditFrequency_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemMasterAuditFrequency_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemMasterAuditFrequencyData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemMasterAuditGenericReducer(state = {
  GetItemMasterAuditGenericData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemMasterAuditGeneric_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemMasterAuditGeneric_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemMasterAuditGeneric_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemMasterAuditGenericData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemMasterAuditCharacteristicsReducer(state = {
  GetItemMasterAuditCharacteristicsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemMasterAuditCharacteristics_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemMasterAuditCharacteristics_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemMasterAuditCharacteristics_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemMasterAuditCharacteristicsData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemMasterAuditLabelInstructionReducer(state = {
  GetItemMasterAuditLabelInstructionData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemMasterAuditLabelInstruction_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemMasterAuditLabelInstruction_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemMasterAuditLabelInstruction_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemMasterAuditLabelInstructionData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemMasterAuditRouteMappingReducer(state = {
  GetItemMasterAuditRouteMappingData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemMasterAuditRouteMapping_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemMasterAuditRouteMapping_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemMasterAuditRouteMapping_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemMasterAuditRouteMappingData: action.payload
      }

    }
    default: return state;
  }

}
//************KC********************

function GetItemMasterReducer(state = {
  GetItemMasterData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemMaster_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemMaster_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemMaster_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemMasterData: action.payload
      }

    }
    default: return state;
  }

}

function GetNewItemReuestReducer(state = {
  GetNewItemReuestData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.NewItemRequest_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.NewItemRequest_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.NewItemRequest_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetNewItemReuestData: action.payload
      }

    }
    default: return state;
  }

}

function GetNewItemRequestReducer(state = {
  GetNewItemRequestData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemMaster_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemMaster_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemMaster_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetNewItemRequestData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemListByDescReducer(state = {
  GetItemListByDescData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemByDesc_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemByDesc_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemByDesc_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemListByDescData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemPurchaseUnitReducer(state = {
  GetItemPurchaseUnitData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemPurchaseUnit_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemPurchaseUnit_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemPurchaseUnit_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemPurchaseUnitData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemChargesReducer(state = {
  GetItemChargesData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemCharges_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemCharges_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemCharges_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemChargesData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemAttributesReducer(state = {
  GetItemAttributesData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemAttributes_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemAttributes_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemAttributes_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemAttributesData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemwithItemUnitMappingReducer(state = {
  GetItemwithItemUnitMappingData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemwithItemUnitMapping_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemwithItemUnitMapping_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemwithItemUnitMapping_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemwithItemUnitMappingData: action.payload
      }

    }
    default: return state;
  }

}
function GetChargeTemplateMainReducer(state = {
  GetChargeTemplateMainData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ChargeTemplateMain_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ChargeTemplateMain_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ChargeTemplateMain_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetChargeTemplateMainData: action.payload
      }

    }
    default: return state;
  }

}
function GetChargeTemplateDetailsReducer(state = {
  GetChargeTemplateDetailsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ChargeTemplateDetails_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ChargeTemplateDetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ChargeTemplateDetails_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetChargeTemplateDetailsData: action.payload
      }

    }
    default: return state;
  }

}
function GetChargeTemplatesReducer(state = {
  GetChargeTemplatesData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ChargeTemplates_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ChargeTemplates_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ChargeTemplates_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetChargeTemplatesData: action.payload
      }

    }
    default: return state;
  }

}
function GetChargeTemplateListReducer(state = {
  GetChargeTemplateListData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ChargeTemplate_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ChargeTemplate_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ChargeTemplate_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetChargeTemplateListData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemChargesByChargeTemplateReducer(state = {
  GetItemChargesByChargeTemplateData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemCharges_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemCharges_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemCharges_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemChargesByChargeTemplateData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemUnitsByIssueUnitReducer(state = {
  GetItemUnitsByIssueUnitData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemwithItemUnitMapping_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemwithItemUnitMapping_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemwithItemUnitMapping_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemUnitsByIssueUnitData: action.payload
      }

    }
    default: return state;
  }

}
function GetTherapeuticCategoryReducer(state = {
  GetTherapeuticCategoryData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.TherapeuticCategory_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.TherapeuticCategory_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.TherapeuticCategory_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetTherapeuticCategoryData: action.payload
      }

    }
    default: return state;
  }

}
function GetTherapeuticSubCategoryReducer(state = {
  GetTherapeuticSubCategoryData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.TherapeuticSubCategory_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.TherapeuticSubCategory_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.TherapeuticSubCategory_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetTherapeuticSubCategoryData: action.payload
      }

    }
    default: return state;
  }

}
function GetGenericMasterReducer(state = {
  GetGenericMasterData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GenericMaster_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GenericMaster_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GenericMaster_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetGenericMasterData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemGenericMappingReducer(state = {
  GetItemGenericData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemGenericMapping_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemGenericMapping_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemGenericMapping_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemGenericData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemFormMasterReducer(state = {
  GetItemFormMasterData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemFormMaster_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemFormMaster_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemFormMaster_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemFormMasterData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemFacilityMappingReducer(state = {
  GetItemFacilityData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemFacilityMapping_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemFacilityMapping_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemFacilityMapping_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemFacilityData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemSubCategoryByCategoryReducer(state = {
  GetItemSubCategoryByCategoryData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemSubCategoryByCategory_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemSubCategoryByCategory_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemSubCategoryByCategory_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemSubCategoryByCategoryData: action.payload
      }

    }
    default: return state;
  }

}

function GetTherapeuticSubCategoryByCategoryReducer(state = {
  GetTherapeuticSubCategoryByCategoryData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.TherapeuticSubCategoryByCategory_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.TherapeuticSubCategoryByCategory_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.TherapeuticSubCategoryByCategory_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetTherapeuticSubCategoryByCategoryData: action.payload
      }

    }
    default: return state;
  }

}

function GetHSNCodeByHSNGroupReducer(state = {
  GetHSNCodeByHSNGroupData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.HSNCodeByHSNGroup_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.HSNCodeByHSNGroup_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.HSNCodeByHSNGroup_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetHSNCodeByHSNGroupData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemAttributesOnlyReducer(state = {
  GetItemAttributesOnlyData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemAttributesOnly_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemAttributesOnly_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemAttributesOnly_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemAttributesOnlyData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemChargesOnlyReducer(state = {
  GetItemChargesOnlyData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemChargesOnly_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemChargesOnly_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemChargesOnly_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemChargesOnlyData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemWithItemUnitsOnlyReducer(state = {
  GetItemWithItemUnitsOnlyData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemWithItemUnitsOnly_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemWithItemUnitsOnly_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemWithItemUnitsOnly_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemWithItemUnitsOnlyData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemGenericOnlyReducer(state = {
  GetItemGenericsOnlyData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemGenericsOnly_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemGenericsOnly_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemGenericsOnly_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemGenericsOnlyData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemFacilityOnlyReducer(state = {
  GetItemFacilityOnlyData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemFacilityOnly_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemFacilityOnly_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemFacilityOnly_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemFacilityOnlyData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemCountByItemSubCategoryReducer(state = {
  GetItemCountByItemSubCategoryData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemByItemSubCategory_COUNT_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemByItemSubCategory_COUNT_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemByItemSubCategory_COUNT_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemCountByItemSubCategoryData: action.payload
      }

    }
    default: return state;
  }

}

function GetGRNMainReducer(state = {
  GetGRNMainData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GRNMain_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GRNMain_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GRNMain_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetGRNMainData: action.payload
      }

    }
    default: return state;
  }

}

function GetGRNDataReducer(state = {
  GetGRNData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GRNData_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GRNData_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GRNData_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetGRNData: action.payload
      }

    }
    default: return state;
  }

}
function GetGRNSearchReducer(state = {
  GetGRNSearchData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GRNSearch_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GRNSearch_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GRNSearch_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetGRNSearchData: action.payload
      }

    }
    default: return state;
  }

}

function GetGRNDetailsReducer(state = {
  GetGRNDetailsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GRNDetails_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GRNDetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GRNDetails_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetGRNDetailsData: action.payload
      }

    }
    default: return state;
  }

}
function GetGRNBatchDetailsReducer(state = {
  GetGRNBatchDetailsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GRNBatchDetails_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GRNBatchDetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GRNBatchDetails_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetGRNBatchDetailsData: action.payload
      }

    }
    default: return state;
  }

}

function GetPOMainReducer(state = {
  GetPOMainData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.POMain_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.POMain_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.POMain_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPOMainData: action.payload
      }

    }
    default: return state;
  }

}
function GetPODataReducer(state = {
  GetPOData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.POData_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.POData_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.POData_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPOData: action.payload
      }

    }
    default: return state;
  }

}
function WorkOrderDataReducer(state = {
  WorkOrderData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.WorkOrderData_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.WorkOrderData_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.WorkOrderData_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        WorkOrderData: action.payload
      }

    }
    default: return state;
  }

}
function GetPOSearchReducer(state = {
  GetPOSearchData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.POSearch_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.POSearch_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.POSearch_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPOSearchData: action.payload
      }

    }
    default: return state;
  }

}
function GetWorkOrderSearchReducer(state = {
  GetWorkOrderSearchData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.WorkOrderSearch_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.WorkOrderSearch_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.WorkOrderSearch_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetWorkOrderSearchData: action.payload
      }

    }
    default: return state;
  }

}

function GetPODetailsReducer(state = {
  GetPODetailsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.PODetails_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.PODetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.PODetails_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPODetailsData: action.payload
      }

    }
    default: return state;
  }

}

function GetPOItemChargesReducer(state = {
  GetPOItemChargesData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.POItemCharges_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.POItemCharges_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.POItemCharges_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPOItemChargesData: action.payload
      }

    }
    default: return state;
  }

}
function GetWorkOrderChargesReducer(state = {
  GetPOItemChargesData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.WorkOrderCharges_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.WorkOrderCharges_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.WorkOrderCharges_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPOItemChargesData: action.payload
      }

    }
    default: return state;
  }

}
function GetChargeTemplateIDReducer(state = {
  GetChargeTemplateIDData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ChargeTemplateID_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ChargeTemplateID_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ChargeTemplateID_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetChargeTemplateIDData: action.payload
      }

    }
    default: return state;
  }

}

function GetPODocumnetChargesReducer(state = {
  GetPODocumentChargesData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.PODocumentCharges_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.PODocumentCharges_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.PODocumentCharges_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPODocumentChargesData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemByAttributeReducer(state = {
  GetItemByAttributeData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemByAttribute_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemByAttribute_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemByAttribute_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemByAttributeData: action.payload
      }

    }
    default: return state;
  }

}

function GetReusableItemReducer(state = {
  GetReusableItemData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ReusableItem_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ReusableItem_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ReusableItem_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetReusableItemData: action.payload
      }

    }
    default: return state;
  }

}

function GetReusableItemsReducer(state = {
  GetReusableItemsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ReusableItems_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ReusableItems_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ReusableItems_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetReusableItemsData: action.payload
      }

    }
    default: return state;
  }

}


function GetReusableItemChargesReducer(state = {
  GetReusableItemChargesData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ReusableItemCharges_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ReusableItemCharges_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ReusableItemCharges_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetReusableItemChargesData: action.payload
      }

    }
    default: return state;
  }

}
function GetChargesByItemReducer(state = {
  GetChargesByItemData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ChargesByItem_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ChargesByItem_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ChargesByItem_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetChargesByItemData: action.payload
      }

    }
    default: return state;
  }

}


function GetGRNItemChargesReducer(state = {
  GetGRNItemChargesData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GRNItemCharges_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GRNItemCharges_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GRNItemCharges_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetGRNItemChargesData: action.payload
      }

    }
    default: return state;
  }

}

function GetChargesCalculationReducer(state = {
  GetChargesCalculationData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ChargesCalculation_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ChargesCalculation_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ChargesCalculation_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetChargesCalculationData: action.payload
      }

    }
    default: return state;
  }

}

function GetGRNDocumnetChargesReducer(state = {
  GetGRNDocumentChargesData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GRNDocumentCharges_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GRNDocumentCharges_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GRNDocumentCharges_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetGRNDocumentChargesData: action.payload
      }

    }
    default: return state;
  }

}

function GetLast5PurchasesReducer(state = {
  GetLast5PurchasesData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.Last5Purchases_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.Last5Purchases_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.Last5Purchases_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetLast5PurchasesData: action.payload
      }

    }
    default: return state;
  }

}
function GetLast5PurchasesConsignmentReducer(state = {
  GetLast5PurchasesConsignmentData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.Last5Purchases_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.Last5Purchases_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.Last5Purchases_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetLast5PurchasesConsignmentData: action.payload
      }

    }
    default: return state;
  }

}
function GetLastPurchasesReducer(state = {
  GetLastPurchasesData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.LastPurchases_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.LastPurchases_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.LastPurchases_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetLastPurchasesData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemsBySupplierReducer(state = {
  GetItemsBySupplierData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemsBySupplier_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemsBySupplier_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemsBySupplier_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemsBySupplierData: action.payload
      }

    }
    default: return state;
  }

}

function GetSuppliersByFacilityReducer(state = {
  GetSuppliersByFacilityData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.SuppliersByFacility_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.SuppliersByFacility_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.SuppliersByFacility_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetSuppliersByFacilityData: action.payload
      }

    }
    default: return state;
  }

}

function GetPrescriptionUnitReducer(state = {
  GetPrescriptionUnitData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.PrescriptionUnit_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.PrescriptionUnit_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.PrescriptionUnit_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPrescriptionUnitData: action.payload
      }

    }
    default: return state;
  }

}

function GetAffiliatedStoreReducer(state = {
  GetAffiliatedStoreData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.AffiliatedStore_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.AffiliatedStore_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.AffiliatedStore_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetAffiliatedStoreData: action.payload
      }

    }
    default: return state;
  }

}
function GetStoresByAffiliatedStoreReducer(state = {
  GetStoresByAffiliatedStoreData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetStoresByAffiliatedStore_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetStoresByAffiliatedStore_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetStoresByAffiliatedStore_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetStoresByAffiliatedStoreData: action.payload
      }

    }
    default: return state;
  }

}
function GetWOSupplierTermsConditionsReducer(state = {
  GetWOSupplierTermsConditionsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.WOSupplierTermsConditions_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.WOSupplierTermsConditions_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.WOSupplierTermsConditions_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetWOSupplierTermsConditionsData: action.payload
      }

    }
    default: return state;
  }

}
function GetSupplierTermsConditionsReducer(state = {
  GetSupplierTermsConditionsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.SupplierTermsConditions_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.SupplierTermsConditions_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.SupplierTermsConditions_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetSupplierTermsConditionsData: action.payload
      }

    }
    default: return state;
  }

}
function GetPOTimeLineReducer(state = {
  GetPOTimeLineData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.POTimeLine_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.POTimeLine_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.POTimeLine_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPOTimeLineData: action.payload
      }

    }
    default: return state;
  }

}
function GetOPReturnTimeLineReducer(state = {
  GetOPReturnTimeLineData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.OPReturnTimeLine_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.OPReturnTimeLine_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.OPReturnTimeLine_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetOPReturnTimeLineData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemWiseBatchesReducer(state = {
  GetItemWiseBatchesData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemwiseBatches_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemwiseBatches_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemwiseBatches_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemWiseBatchesData: action.payload
      }

    }
    default: return state;
  }

}

function GetPurchaseIndentforPOReducer(state = {
  GetPurchaseIndentforPOData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.PurchaseIndentforPO_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.PurchaseIndentforPO_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.PurchaseIndentforPO_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPurchaseIndentforPOData: action.payload
      }

    }
    default: return state;
  }

}

function GetIPIssueStoreListforIPReturnReducer(state = {
  GetIPIssueStoreListforIPReturnData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetIPIssueStoreListforIPReturn_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetIPIssueStoreListforIPReturn_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetIPIssueStoreListforIPReturn_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetIPIssueStoreListforIPReturnData: action.payload
      }

    }
    default: return state;
  }

}

function GetIPReturnMainReducer(state = {
  GetIPReturnMainData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.IPReturn_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.IPReturn_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.IPReturn_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetIPReturnMainData: action.payload
      }

    }
    default: return state;
  }

}

function GetIPReturnDataReducer(state = {
  GetIPReturnData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.IPReturnData_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.IPReturnData_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.IPReturnData_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetIPReturnData: action.payload
      }

    }
    default: return state;
  }

}

function GetIPReturnSearchReducer(state = {
  GetIPReturnSearchData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.IPReturnSearch_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.IPReturnSearch_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.IPReturnSearch_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetIPReturnSearchData: action.payload
      }

    }
    default: return state;
  }

}

function GetStoreWiseItemListforIPReturnReducer(state = {
  GetStoreWiseItemListforIPReturnData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetStoreWiseItemListforIPReturn_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetStoreWiseItemListforIPReturn_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetStoreWiseItemListforIPReturn_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetStoreWiseItemListforIPReturnData: action.payload
      }

    }
    default: return state;
  }

}

function GetStoreWiseIPIssuedItemsforIPReturnReducer(state = {
  GetStoreWiseIPIssuedItemsforIPReturnData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetStoreWiseIPIssuedItemsforIPReturn_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetStoreWiseIPIssuedItemsforIPReturn_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetStoreWiseIPIssuedItemsforIPReturn_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetStoreWiseIPIssuedItemsforIPReturnData: action.payload
      }

    }
    default: return state;
  }

}
function GetIPReturnTimeLineReducer(state = {
  GetIPReturnTimeLineData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.IPReturnTimeLine_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.IPReturnTimeLine_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.IPReturnTimeLine_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetIPReturnTimeLineData: action.payload
      }

    }
    default: return state;
  }

}

function GetChargeTemplateIdByCodeReducer(state = {
  GetChargeTemplateIdByCodeData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ChargeTemplateIdByCode_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ChargeTemplateIdByCode_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ChargeTemplateIdByCode_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetChargeTemplateIdByCodeData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemPriceReducer(state = {
  GetItemPriceData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemPrice_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemPrice_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemPrice_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemPriceData: action.payload
      }

    }
    default: return state;
  }

}


function GetConsignmentMainReducer(state = {
  GetConsignmentMainData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ConsignmentMain_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ConsignmentMain_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ConsignmentMain_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetConsignmentMainData: action.payload
      }

    }
    default: return state;
  }

}

function GetConsignmentDataReducer(state = {
  GetConsignmentData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ConsignmentData_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ConsignmentData_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ConsignmentData_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetConsignmentData: action.payload
      }

    }
    default: return state;
  }

}

function GetConsignmentSearchReducer(state = {
  GetConsignmentSearchData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ConsignmentSearch_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ConsignmentSearch_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ConsignmentSearch_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetConsignmentSearchData: action.payload
      }

    }
    default: return state;
  }

}

function GetConsignmentDetailsReducer(state = {
  GetConsignmentDetailsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ConsignmentDetails_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ConsignmentDetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ConsignmentDetails_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetConsignmentDetailsData: action.payload
      }

    }
    default: return state;
  }

}

function GetConsignmentBatchDetailsReducer(state = {
  GetConsignmentBatchDetailsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ConsignmentBatchDetails_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ConsignmentBatchDetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ConsignmentBatchDetails_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetConsignmentBatchDetailsData: action.payload
      }

    }
    default: return state;
  }

}

function GetConsignmentItemChargesReducer(state = {
  GetConsignmentItemChargesData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ConsignmentItemCharges_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ConsignmentItemCharges_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ConsignmentItemCharges_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetConsignmentItemChargesData: action.payload
      }

    }
    default: return state;
  }

}

function GetConsignmentReceiveMainReducer(state = {
  GetConsignmentReceiveMainData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ConsignmentReceiveMain_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ConsignmentReceiveMain_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ConsignmentReceiveMain_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetConsignmentReceiveMainData: action.payload
      }

    }
    default: return state;
  }

}
function GetConsignmentAllocationSearchReducer(state = {
  GetConsignmentAllocationSearchData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetConsignmentAllocationSearch_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetConsignmentAllocationSearch_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetConsignmentAllocationSearch_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetConsignmentAllocationSearchData: action.payload
      }

    }
    default: return state;
  }

}

function GetConsignmentReturnSearchReducer(state = {
  GetConsignmentReturnSearchData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetConsignmentReturnSearch_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetConsignmentReturnSearch_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetConsignmentReturnSearch_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetConsignmentReturnSearchData: action.payload
      }

    }
    default: return state;
  }

}

function GetConsignmentReceiveDataReducer(state = {
  GetConsignmentReceiveData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ConsignmentReceiveData_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ConsignmentReceiveData_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ConsignmentReceiveData_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetConsignmentReceiveData: action.payload
      }

    }
    default: return state;
  }

}

function GetConsignmentReceiveSearchReducer(state = {
  GetConsignmentReceiveSearchData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ConsignmentReceiveSearch_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ConsignmentReceiveSearch_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ConsignmentReceiveSearch_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetConsignmentReceiveSearchData: action.payload
      }

    }
    default: return state;
  }

}

function GetConsignmentReceiveDetailsReducer(state = {
  GetConsignmentReceiveDetailsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ConsignmentReceiveDetails_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ConsignmentReceiveDetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ConsignmentReceiveDetails_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetConsignmentReceiveDetailsData: action.payload
      }

    }
    default: return state;
  }

}

function GetConsignmentReceiveBatchDetailsReducer(state = {
  GetConsignmentReceiveBatchDetailsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ConsignmentReceiveBatchDetails_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ConsignmentReceiveBatchDetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ConsignmentReceiveBatchDetails_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetConsignmentReceiveBatchDetailsData: action.payload
      }

    }
    default: return state;
  }

}

function GetConsignmentReceiveItemChargesReducer(state = {
  GetConsignmentReceiveItemChargesData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ConsignmentReceiveItemCharges_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ConsignmentReceiveItemCharges_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ConsignmentReceiveItemCharges_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetConsignmentReceiveItemChargesData: action.payload
      }

    }
    default: return state;
  }

}

function GetStoresByStoreTypeCodeReducer(state = {
  GetStoresByStoreTypeCodeData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.StoresByStoreTypeCode_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.StoresByStoreTypeCode_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.StoresByStoreTypeCode_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetStoresByStoreTypeCodeData: action.payload
      }

    }
    default: return state;
  }

}

function GetPOApprovalListByFacilityReducer(state = {
  GetPOApprovalListByFacilityData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.POAPPROVALLISTBYFACILITY_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.POAPPROVALLISTBYFACILITY_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.POAPPROVALLISTBYFACILITY_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPOApprovalListByFacilityData: action.payload
      }

    }
    default: return state;
  }

}

//---START----------------------------POApproval Setup-----------------------------------By Shashi Prasad
//PO Approval Setup Reducer
function GetPOApprovalSetupReducer(
  state = {
    POApprovalSetupData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.POAPPROVALSETUP_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.POAPPROVALSETUP_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.POAPPROVALSETUP_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        POApprovalSetupData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get Supplier Detail
function GetSupplierDetailReducer(
  state = {
    SupplierDetailData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.SUPPLIERDETAIL_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.SUPPLIERDETAIL_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.SUPPLIERDETAIL_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        SupplierDetailData: action.payload,
      };
    }
    default:
      return state;
  }
}

//Get Supplier Terms & Condition Detail
function GetSupplierTermsConditiontReducer(
  state = {
    SupplierTermsConditionData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.SUPPLIERTERMSCONDITION_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.SUPPLIERTERMSCONDITION_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.SUPPLIERTERMSCONDITION_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        SupplierTermsConditionData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get Terms & Condition Master Detail
function GetTermsConditionReducer(
  state = {
    TermsConditionData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.TERMS_CONDITION_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.TERMS_CONDITION_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.TERMS_CONDITION_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        TermsConditionData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get Supplier Item Mapping Detail
function GetSupplierItemMappingReducer(
  state = {
    SupplierItemMappingData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.SUPPLIER_ITEM_MAPPING_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.SUPPLIER_ITEM_MAPPING_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.SUPPLIER_ITEM_MAPPING_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        SupplierItemMappingData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get Supplier Facility Mapping Detail
function GetSupplierFacilityMappingReducer(
  state = {
    SupplierFacilityMappingData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.SUPPLIER_FACILITY_MAPPING_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.SUPPLIER_FACILITY_MAPPING_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.SUPPLIER_FACILITY_MAPPING_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        SupplierFacilityMappingData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get Supplier Login Detail
function GetSupplierLoginDetailReducer(
  state = {
    SupplierLoginData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.SUPPLIER_LOGIN_DETAIL_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.SUPPLIER_LOGIN_DETAIL_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.SUPPLIER_LOGIN_DETAIL_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        SupplierLoginData: action.payload,
      };
    }
    default:
      return state;
  }
}

function CheckSupplierStateIdReducer(
  state = {
    CheckSupplierStateData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.SuppliersOfState_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.SuppliersOfState_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.SuppliersOfState_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        CheckSupplierStateData: action.payload,
      };
    }
    default:
      return state;
  }
}
//--------------
//Get Store Detail
function GetStoreReducer(
  state = {
    StoreData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.STORE_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.STORE_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.STORE_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        StoreData: action.payload,
      };
    }
    default:
      return state;
  }
}



function GetStorefacilityWiseReducer(
  state = {
    StorefacilityWiseData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.STOREfacilityWise_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.STOREfacilityWise_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.STOREfacilityWise_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        StorefacilityWiseData: action.payload,
      };
    }
    default:
      return state;
  }
}

function GetEMPStoreReducer(
  state = {
    EMPStoreData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.EMPSTORE_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.EMPSTORE_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.EMPSTORE_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        EMPStoreData: action.payload,
      };
    }
    default:
      return state;
  }
}

function GetEMPStoreReportMappedReducer(
  state = {
    EMPStoreReportMappedData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.EMPSTOREReportMapped_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.EMPSTOREReportMapped_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.EMPSTOREReportMapped_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        EMPStoreReportMappedData: action.payload,
      };
    }
    default:
      return state;
  }
}
function GetStoresByTransTypeReducer(
  state = {
    StoresByTransTypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.StoresByTransType_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.StoresByTransType_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.StoresByTransType_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        StoresByTransTypeData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get Store Transaction type Detail
function GetStoreTransactionTypeReducer(
  state = {
    StoreTransactionTypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.STORE_TRANSACTION_TYPE_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.STORE_TRANSACTION_TYPE_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.STORE_TRANSACTION_TYPE_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        StoreTransactionTypeData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get Store All Transaction type Detail
function GetStoreAllTransactionTypeReducer(
  state = {
    StoreAllTransactionTypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.SOTRE_ALL_TRANSACTIONS_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.SOTRE_ALL_TRANSACTIONS_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.SOTRE_ALL_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        StoreAllTransactionTypeData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get Store Item Mapping
function GetStoreItemMappingReducer(
  state = {
    StoreItemMappingData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.STORE_ITEM_MAPPING_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.STORE_ITEM_MAPPING_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.STORE_ITEM_MAPPING_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        StoreItemMappingData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get Store's  Item Sub Category or Item Mapping
function GetStoreSubCategoryOrItemMappingReducer(
  state = {
    StoreSubCategoryOrItemMappingData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.STORE_SUBCATEGORY_OR_ITEM_MAPPING_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.STORE_SUBCATEGORY_OR_ITEM_MAPPING_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.STORE_SUBCATEGORY_OR_ITEM_MAPPING_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        StoreSubCategoryOrItemMappingData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get Store's  Item Auto Consumption Mapping
function GetStoreItemAutoConsumptionMappingReducer(
  state = {
    GetStoreItemAutoConsumptionMappingData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.STORE_ItemAutoConsumptionMapping_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.STORE_ItemAutoConsumptionMapping_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.STORE_ItemAutoConsumptionMapping_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetStoreItemAutoConsumptionMappingData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get Store's all Item Sub Category and Item Mapping mapped and unmapped
function GetStoreAllSubItemCategoryAndItemMappingReducer(
  state = {
    GetStoreAllSubItemCategoryAndItemMappingData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.STORE_ALL_SUBCATEGORY_AND_ITEM_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.STORE_ALL_SUBCATEGORY_AND_ITEM_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.STORE_ALL_SUBCATEGORY_AND_ITEM_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetStoreAllSubItemCategoryAndItemMappingData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get Store ROL Setup
function GetStoreROLSetupReducer(
  state = {
    StoreROLSetupData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.STORE_ROLSETUP_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.STORE_ROLSETUP_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.STORE_ROLSETUP_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        StoreROLSetupData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get Store Indent Affilliation
function GetStoreIndentAffilliationReducer(
  state = {
    StoreAffilliationData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.STORE_AFFILLIATION_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.STORE_AFFILLIATION_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.STORE_AFFILLIATION_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        StoreAffilliationData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get Store Auto Indent Frequency
function GetStoreAutoIndentFrequency(
  state = {
    StoreAutoIndentFrequencyData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.STORE_AUTO_INDENT_FREQUENCY_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.STORE_AUTO_INDENT_FREQUENCY_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.STORE_AUTO_INDENT_FREQUENCY_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        StoreAutoIndentFrequencyData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get Bin Location
function GetBinLocationReducer(
  state = {
    BinLocationData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.BIN_LOCATION_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.BIN_LOCATION_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.BIN_LOCATION_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        BinLocationData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get Financial Year
function GetFinancialYearReducer(
  state = {
    GetFinancialYearData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.FINANCIALYEAR_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.FINANCIALYEAR_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.FINANCIALYEAR_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetFinancialYearData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get Financial Year
function GetAllItemChargeTemplateReducer(
  state = {
    GetAllItemChargeTemplateData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.ALL_ITEMCHARGE_TEMPLATE_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.ALL_ITEMCHARGE_TEMPLATE_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.ALL_ITEMCHARGE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetAllItemChargeTemplateData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get Item Sale Price Setup
function GetItemSalePriceSetupReducer(
  state = {
    GetItemSalePriceSetupData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.ITEM_SALE_PRICE_SETUP_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.ITEM_SALE_PRICE_SETUP_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.ITEM_SALE_PRICE_SETUP_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemSalePriceSetupData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get KIT Master
function GetKITMasterReducer(
  state = {
    GetKITMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.KITMASTER_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.KITMASTER_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.KITMASTER_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetKITMasterData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get KIT Item Mapping
function GetKITItemMappingReducer(
  state = {
    GetKITItemMappingData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.KIT_ITEM_MAPPING_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.KIT_ITEM_MAPPING_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.KIT_ITEM_MAPPING_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetKITItemMappingData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get Get Department Indent Details
function GetDepartmentIndentDetailReducer(
  state = {
    GetDepartmentIndentDetailData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.DEPARTMENT_INDENT_DETAILS_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.DEPARTMENT_INDENT_DETAILS_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.DEPARTMENT_INDENT_DETAILS_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetDepartmentIndentDetailData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get KIT Item Mapping
function GetItemForDepartmentIndentReducer(
  state = {
    GetItemForDepartmentIndentData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.ITEMS_FOR_DEPARTMENTINDENT_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.ITEMS_FOR_DEPARTMENTINDENT_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.ITEMS_FOR_DEPARTMENTINDENT_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemForDepartmentIndentData: action.payload,
      };
    }
    default:
      return state;
  }
}
//Get KIT Item Mapping
function GetDepartmentIndentMainReducer(
  state = {
    GetDepartmentIndentMainData: intialstate,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INVM_GETConstants.DEPARTMENT_INDENT_MAIN_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null };
    }
    case INVM_GETConstants.DEPARTMENT_INDENT_MAIN_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true };
    }
    case INVM_GETConstants.DEPARTMENT_INDENT_MAIN_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetDepartmentIndentMainData: action.payload,
      };
    }
    default:
      return state;
  }
}
function GetItemMasterBySubCatReducer(state = {
  GetItemMasterBySubCatData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemMasterBySubCat_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemMasterBySubCat_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemMasterBySubCat_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemMasterBySubCatData: action.payload
      }

    }
    default: return state;
  }

}
function GetDepartmentIndentTimeLineReducer(state = {
  GetDepartmentIndentTimeLineData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.DEPARTMENTINDENT_TIMELINE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.DEPARTMENTINDENT_TIMELINE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.DEPARTMENTINDENT_TIMELINE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetDepartmentIndentTimeLineData: action.payload
      }

    }
    default: return state;
  }

}
function GetStoreMappedWithDocumentSetupReducer(state = {
  GetStoreMappedWithDocumentSetupData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetStoreMappedWithDocumentSetup_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetStoreMappedWithDocumentSetup_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetStoreMappedWithDocumentSetup_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetStoreMappedWithDocumentSetupData: action.payload
      }

    }
    default: return state;
  }
}
function GetDepartmentIndentApprovalDetailsReducer(state = {
  GetDepartmentIndentApprovalDetailsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.DEPARTMENTINDENT_APPROVALDETAILS_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.DEPARTMENTINDENT_APPROVALDETAILS_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.DEPARTMENTINDENT_APPROVALDETAILS_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetDepartmentIndentApprovalDetailsData: action.payload
      }

    }
    default: return state;
  }
}
function GetItemSubstitutionAndBatchSelectionReducer(state = {
  GetItemSubstitutionAndBatchSelectionData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ITEMSUBSTITUTE_BATCHSELECTION_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ITEMSUBSTITUTE_BATCHSELECTION_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ITEMSUBSTITUTE_BATCHSELECTION_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemSubstitutionAndBatchSelectionData: action.payload
      }

    }
    default: return state;
  }
}

function GetItemSelectionReducer(state = {
  GetItemSelectionData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ITEMSELECTION_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ITEMSELECTION_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ITEMSELECTION_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemSelectionData: action.payload
      }

    }
    default: return state;
  }
}

function GetIndentForIssueReducer(state = {
  GetIndentForIssueData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.INDENTFORISSUE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.INDENTFORISSUE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.INDENTFORISSUE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetIndentForIssueData: action.payload
      }

    }
    default: return state;
  }
}

function GetIndentForIssuePicklistPrintReducer(state = {
  GetIndentForIssuePicklistPrintData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.INDENTFORISSUEPicklistPrint_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.INDENTFORISSUEPicklistPrint_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.INDENTFORISSUEPicklistPrint_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetIndentForIssuePicklistPrintData: action.payload
      }

    }
    default: return state;
  }
}

function GetBatchByBarcodeReducer(state = {
  GetBatchByBarcodeData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GETBATCHBYBARCODE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GETBATCHBYBARCODE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GETBATCHBYBARCODE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetBatchByBarcodeData: action.payload
      }

    }
    default: return state;
  }
}

function GetDepartmentIssueReducer(state = {
  GetDepartmentIssueData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.INDENTRECEIVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.INDENTRECEIVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.INDENTRECEIVE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetDepartmentIssueData: action.payload
      }

    }
    default: return state;
  }
}

function GetDepartmentReceiveReducer(state = {
  GetDepartmentReceiveData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.INDENT_RECEIVED_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.INDENT_RECEIVED_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.INDENT_RECEIVED_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetDepartmentReceiveData: action.payload
      }

    }
    default: return state;
  }
}
function GetDepartmentReturnReducer(state = {
  GetDepartmentReturnData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.INDENT_RETURN_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.INDENT_RETURN_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.INDENT_RETURN_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetDepartmentReturnData: action.payload
      }

    }
    default: return state;
  }
}
function GetDepartmentReturnSearchReducer(state = {
  GetDepartmentReturnSearchData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.INDENT_RETURN_SEARCH_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.INDENT_RETURN_SEARCH_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.INDENT_RETURN_SEARCH_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetDepartmentReturnSearchData: action.payload
      }

    }
    default: return state;
  }
}
function GetItemForDepartmentReturnReducer(state = {
  GetItemForDepartmentReturnData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ITEMS_FOR_DEPARTENTRETURN_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ITEMS_FOR_DEPARTENTRETURN_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ITEMS_FOR_DEPARTENTRETURN_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemForDepartmentReturnData: action.payload
      }

    }
    default: return state;
  }
}
function GetItemForDepartmentReturnByBarCodeReducer(state = {
  GetItemForDepartmentReturnByBarCodeData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemForDepartmentReturnByBarCode_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemForDepartmentReturnByBarCode_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemForDepartmentReturnByBarCode_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemForDepartmentReturnByBarCodeData: action.payload
      }

    }
    default: return state;
  }
}
function GetUserStoreAccessRightsReducer(state = {
  GetUserStoreAccessRightsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GET_USER_STORE_ACCESS_RIGHTS_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GET_USER_STORE_ACCESS_RIGHTS_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GET_USER_STORE_ACCESS_RIGHTS_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetUserStoreAccessRightsData: action.payload
      }

    }
    default: return state;
  }
}

function GetDepartmentReceiveSearchPopupReducer(state = {
  GetDepartmentReceiveSearchPopupData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.INDENT_RECEIVED_SEARCHPOPUP_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.INDENT_RECEIVED_SEARCHPOPUP_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.INDENT_RECEIVED_SEARCHPOPUP_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetDepartmentReceiveSearchPopupData: action.payload
      }

    }
    default: return state;
  }
}

function GetStockTransferSearchSearchPopupReducer(state = {
  GetStockTransferSearchSearchPopupData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetStockTransferSearchSearchPopup_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetStockTransferSearchSearchPopup_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetStockTransferSearchSearchPopup_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetStockTransferSearchSearchPopupData: action.payload
      }

    }
    default: return state;
  }
}

function GetItemListROLReducer(state = {
  GetItemListROLData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemListROL_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemListROL_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemListROL_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemListROLData: action.payload
      }

    }
    default: return state;
  }
}

function GetItemListConsumptionReducer(state = {
  GetItemListConsumptionData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemListConsumption_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemListConsumption_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemListConsumption_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemListConsumptionData: action.payload
      }

    }
    default: return state;
  }
}
function GetPurchaseIndentMainReducer(state = {
  GetPurchaseIndentMainData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.PURCHASEINDENTMAIN_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.PURCHASEINDENTMAIN_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.PURCHASEINDENTMAIN_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPurchaseIndentMainData: action.payload
      }

    }
    default: return state;
  }
}
function GetPurchaseIndentDetailsReducer(state = {
  GetPurchaseIndentDetailsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.PURCHASEINDENTDETAIL_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.PURCHASEINDENTDETAIL_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.PURCHASEINDENTDETAIL_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPurchaseIndentDetailsData: action.payload
      }

    }
    default: return state;
  }
}
function GetItemDataReducer(state = {
  GetItemData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GETITEMDATA_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GETITEMDATA_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GETITEMDATA_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemData: action.payload
      }

    }
    default: return state;
  }
}
function getPurchaseIndentAgainstIndentReducer(state = {
  getPurchaseIndentAgainstIndentData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GETPURCHASEINDENTAGAINSTINDENT_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GETPURCHASEINDENTAGAINSTINDENT_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GETPURCHASEINDENTAGAINSTINDENT_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        getPurchaseIndentAgainstIndentData: action.payload
      }

    }
    default: return state;
  }
}
function GetPurchaseIndentTimeLineReducer(state = {
  GetPurchaseIndentTimeLineData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.PURCHASEINDENT_TIMELINE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.PURCHASEINDENT_TIMELINE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.PURCHASEINDENT_TIMELINE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPurchaseIndentTimeLineData: action.payload
      }

    }
    default: return state;
  }
}

function GetDepartmentConsumptionDetailReducer(state = {
  GetDepartmentConsumptionDetailData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetDepartmentConsumptionDetail_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetDepartmentConsumptionDetail_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetDepartmentConsumptionDetail_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetDepartmentConsumptionDetailData: action.payload
      }

    }
    default: return state;
  }
}
function GetAllDepartmentConsumptionReducer(state = {
  GetAllDepartmentConsumptionData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetAllDepartmentConsumption_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetAllDepartmentConsumption_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetAllDepartmentConsumption_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetAllDepartmentConsumptionData: action.payload
      }

    }
    default: return state;
  }
}
function GetKitTemplateItemWithBatchReducer(state = {
  GetKitTemplateItemWithBatchData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetKitTemplateItemWithBatch_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetKitTemplateItemWithBatch_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetKitTemplateItemWithBatch_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetKitTemplateItemWithBatchData: action.payload
      }

    }
    default: return state;
  }
}
function GetIPIssueAllocationReducer(state = {
  GetIPIssueAllocationData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GETIPISSUEALLOCATION_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GETIPISSUEALLOCATION_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GETIPISSUEALLOCATION_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetIPIssueAllocationData: action.payload
      }

    }
    default: return state;
  }
}

function GetRxOrderDetailsReducer(state = {
  GetRxOrderDetailsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetRxOrderDetails_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetRxOrderDetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetRxOrderDetails_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetRxOrderDetailsData: action.payload
      }

    }
    default: return state;
  }
}
function GetItemListforSupplierReturnReducer(state = {
  GetItemListforSupplierReturnData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemListforSupplierReturn_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemListforSupplierReturn_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemListforSupplierReturn_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemListforSupplierReturnData: action.payload
      }

    }
    default: return state;
  }
}
function GetItemListforSupplierReturnByBarcodeReducer(state = {
  GetItemListforSupplierReturnByBarcodeData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemListforSupplierReturnByBarcode_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemListforSupplierReturnByBarcode_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemListforSupplierReturnByBarcode_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemListforSupplierReturnByBarcodeData: action.payload
      }

    }
    default: return state;
  }
}
function GetSupplierReturnDetailsReducer(state = {
  GetSupplierReturnDetailsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetSupplierReturnDetails_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetSupplierReturnDetails_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetSupplierReturnDetails_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetSupplierReturnDetailsData: action.payload
      }

    }
    default: return state;
  }
}
function GetSupplierReturnSearchReducer(state = {
  GetSupplierReturnSearchData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetSupplierReturnSearch_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetSupplierReturnSearch_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetSupplierReturnSearch_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetSupplierReturnSearchData: action.payload
      }

    }
    default: return state;
  }
}
function GetSupplierReturnTimeLineReducer(state = {
  GetSupplierReturnTimeLineData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetSupplierReturnTimeLine_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetSupplierReturnTimeLine_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetSupplierReturnTimeLine_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetSupplierReturnTimeLineData: action.payload
      }

    }
    default: return state;
  }
}

function GetOpeningAdjustmentTimeLineReducer(state = {
  GetOpeningAdjustmentTimeLineData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetOpeningAdjustmentTimeLine_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetOpeningAdjustmentTimeLine_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetOpeningAdjustmentTimeLine_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetOpeningAdjustmentTimeLineData: action.payload
      }

    }
    default: return state;
  }
}
function GetSupplierReturnDetailsForGRNReducer(state = {
  GetSupplierReturnDetailsForGRNData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetSupplierReturnDetailsForGRN_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetSupplierReturnDetailsForGRN_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetSupplierReturnDetailsForGRN_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetSupplierReturnDetailsForGRNData: action.payload
      }

    }
    default: return state;
  }
}

function GetItemsForQuotationReducer(state = {
  GetItemsForQuotationData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemsForQuotation_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemsForQuotation_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemsForQuotation_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemsForQuotationData: action.payload
      }

    }
    default: return state;
  }
}
function GetQuotationSearchReducer(state = {
  GetQuotationSearchData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetQuotationSearch_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetQuotationSearch_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetQuotationSearch_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetQuotationSearchData: action.payload
      }

    }
    default: return state;
  }
}
function GetQuotationReducer(state = {
  GetQuotationData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetQuotation_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetQuotation_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetQuotation_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetQuotationData: action.payload
      }

    }
    default: return state;
  }
}
function QuotationItemChargesReducer(state = {
  QuotationItemChargesData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.QuotationItemCharges_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.QuotationItemCharges_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.QuotationItemCharges_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        QuotationItemChargesData: action.payload
      }

    }
    default: return state;
  }
}
function ViewAllQuotationsItemsReducer(state = {
  ViewAllQuotationsItemsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ViewAllQuotationsItems_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ViewAllQuotationsItems_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ViewAllQuotationsItems_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        ViewAllQuotationsItemsData: action.payload
      }

    }
    default: return state;
  }
}
function GetDepartmentIndentAllocationReducer(state = {
  GetDepartmentIndentAllocationData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetDepartmentIndentAllocation_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetDepartmentIndentAllocation_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetDepartmentIndentAllocation_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetDepartmentIndentAllocationData: action.payload
      }

    }
    default: return state;
  }
}
function SearchSaleToPatientReducer(state = {
  SearchSaleToPatientData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.SearchSaleToPatient_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.SearchSaleToPatient_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.SearchSaleToPatient_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        SearchSaleToPatientData: action.payload
      }

    }
    default: return state;
  }
}
function GetItemGenericReducer(state = {
  GetItemGenericData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemGeneric_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemGeneric_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemGeneric_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemGenericData: action.payload
      }

    }
    default: return state;
  }
}

function GetItemByMultipleStoresAsyncReducer(state = {
  GetItemByMultipleStoresAsyncData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemByMultipleStoresAsync_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemByMultipleStoresAsync_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemByMultipleStoresAsync_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemByMultipleStoresAsyncData: action.payload
      }

    }
    default: return state;
  }
}
function GetManufacturerSupplierMappingReducer(state = {
  GetManufacturerSupplierMappingData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetManufacturerSupplierMapping_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetManufacturerSupplierMapping_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetManufacturerSupplierMapping_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetManufacturerSupplierMappingData: action.payload
      }

    }
    default: return state;
  }
}
function SendEmailReducer(state = {
  SendEmailData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.SendEmail_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.SendEmail_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.SendEmail_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        SendEmailData: action.payload
      }

    }
    default: return state;
  }
}

function GetConsignmentItemWithStockReducer(state = {
  GetConsignmentItemWithStockData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetConsignmentItemWithStock_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetConsignmentItemWithStock_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetConsignmentItemWithStock_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetConsignmentItemWithStockData: action.payload
      }

    }
    default: return state;
  }
}
function GetConsignmentItemWithStockByChallanReducer(state = {
  GetConsignmentItemWithStockByChallanData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetConsignmentItemWithStockByChallan_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetConsignmentItemWithStockByChallan_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetConsignmentItemWithStockByChallan_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetConsignmentItemWithStockByChallanData: action.payload
      }

    }
    default: return state;
  }
}

function GetConsignmentAllocationDetailReducer(state = {
  GetConsignmentAllocationDetailData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetConsignmentAllocationDetail_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetConsignmentAllocationDetail_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetConsignmentAllocationDetail_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetConsignmentAllocationDetailData: action.payload
      }

    }
    default: return state;
  }
}
function GetConsignmentIssueDetailReducer(state = {
  GetConsignmentIssueDetailData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetConsignmentIssueDetail_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetConsignmentIssueDetail_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetConsignmentIssueDetail_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetConsignmentIssueDetailData: action.payload
      }

    }
    default: return state;
  }
}
function GetConsignmentIssueListReducer(state = {
  GetConsignmentIssueListData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetConsignmentIssueList_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetConsignmentIssueList_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetConsignmentIssueList_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetConsignmentIssueListData: action.payload
      }

    }
    default: return state;
  }
}
function GetPendingIndentListReducer(state = {
  GetPendingIndentListData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetPendingIndentList_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetPendingIndentList_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetPendingIndentList_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPendingIndentListData: action.payload
      }

    }
    default: return state;
  }
}
function ItemsByFacilityReducer(state = {
  ItemsByFacilityData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemsByFacility_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemsByFacility_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemsByFacility_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        ItemsByFacilityData: action.payload
      }

    }
    default: return state;
  }
}
function GetPendingIndentReceiveReducer(state = {
  GetPendingIndentReceiveData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetPendingIndentReceive_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetPendingIndentReceive_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetPendingIndentReceive_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPendingIndentReceiveData: action.payload
      }

    }
    default: return state;
  }
}
function GetReportMasterReducer(state = {
  GetReportMasterData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetReportMaster_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetReportMaster_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetReportMaster_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetReportMasterData: action.payload
      }

    }
    default: return state;
  }
}
function GetDepartmentIndentAllocationSearchReducer(state = {
  GetDepartmentIndentAllocationSearchData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetDepartmentIndentAllocationSearch_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetDepartmentIndentAllocationSearch_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetDepartmentIndentAllocationSearch_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetDepartmentIndentAllocationSearchData: action.payload
      }

    }
    default: return state;
  }
}
function GetDepartmentDeliverySearchReducer(state = {
  GetDepartmentDeliverySearchData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetDepartmentDeliverySearch_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetDepartmentDeliverySearch_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetDepartmentDeliverySearch_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetDepartmentDeliverySearchData: action.payload
      }

    }
    default: return state;
  }
}


function GetPendingIndentListByItemReducer(state = {
  GetPendingIndentListByItemData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetPendingIndentListByItem_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetPendingIndentListByItem_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetPendingIndentListByItem_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPendingIndentListByItemData: action.payload
      }

    }
    default: return state;
  }
}
function GetItemListByItemNameReducer(state = {
  GetItemListByItemNameData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemListByItemName_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemListByItemName_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemListByItemName_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemListByItemNameData: action.payload
      }

    }
    default: return state;
  }
}

function GetItemListByItemNameExportReducer(state = {
  GetItemListByItemNameDataExport: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemListByItemNameExport_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemListByItemNameExport_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemListByItemNameExport_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemListByItemNameDataExport: action.payload
      }

    }
    default: return state;
  }
}
function GetRequiredItemListByItemNameReducer(state = {
  GetRequiredItemListByItemNameData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetRequiredItemListByItemName_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetRequiredItemListByItemName_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetRequiredItemListByItemName_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetRequiredItemListByItemNameData: action.payload
      }

    }
    default: return state;
  }
}
function GetItemNameSetupReducer(state = {
  GetItemNameSetupData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemNameSetup_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemNameSetup_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemNameSetup_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemNameSetupData: action.payload
      }

    }
    default: return state;
  }
}
function GetItemStrengthMasterReducer(state = {
  GetItemStrengthMasterData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemStrengthMaster_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemStrengthMaster_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemStrengthMaster_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemStrengthMasterData: action.payload
      }

    }
    default: return state;
  }
}
function GetItemEnterpriseLevelAttributesReducer(state = {
  GetItemEnterpriseLevelAttributesData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemEnterpriseLevelAttributes_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemEnterpriseLevelAttributes_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemEnterpriseLevelAttributes_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemEnterpriseLevelAttributesData: action.payload
      }

    }
    default: return state;
  }
}
function GetItemReferenceSizeMasterReducer(state = {
  GetItemReferenceSizeMasterData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemReferenceSizeMaster_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemReferenceSizeMaster_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemReferenceSizeMaster_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemReferenceSizeMasterData: action.payload
      }

    }
    default: return state;
  }
}
function GetItemPackSizeMasterReducer(state = {
  GetItemPackSizeMasterData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemPackSizeMaster_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemPackSizeMaster_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemPackSizeMaster_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemPackSizeMasterData: action.payload
      }

    }
    default: return state;
  }
}
function GetPOPlannerReducer(state = {
  GetPOPlannerData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetPOPlanner_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetPOPlanner_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetPOPlanner_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPOPlannerData: action.payload
      }

    }
    default: return state;
  }
}
function GetItemUniqueCategoryMasterReducer(state = {
  GetItemUniqueCategoryMasterData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemUniqueCategoryMaster_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemUniqueCategoryMaster_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemUniqueCategoryMaster_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemUniqueCategoryMasterData: action.payload
      }

    }
    default: return state;
  }
}

function GetItemNamesReducer(state = {
  GetItemNamesData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemNames_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemNames_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemNames_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemNamesData: action.payload
      }

    }
    default: return state;
  }
}
function GetItemConsumtionReducer(state = {
  GetItemConsumtionData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemConsumtion_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemConsumtion_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemConsumtion_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemConsumtionData: action.payload
      }

    }
    default: return state;
  }
}
function GetDocumentsListReducer(state = {
  GetDocumentsListData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetDocumentsList_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetDocumentsList_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetDocumentsList_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetDocumentsListData: action.payload
      }

    }
    default: return state;
  }
}
function GetItemListReadyToReuseByBatchIdReducer(state = {
  GetItemListReadyToReuseByBatchIdData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemListReadyToReuseByBatchId_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemListReadyToReuseByBatchId_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemListReadyToReuseByBatchId_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemListReadyToReuseByBatchIdData: action.payload
      }

    }
    default: return state;
  }
}
function POGRNReceivedDetailsReducer(state = {
  POGRNReceivedDetailsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.POGRNReceivedDetails_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.POGRNReceivedDetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.POGRNReceivedDetails_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        POGRNReceivedDetailsData: action.payload
      }

    }
    default: return state;
  }
}
//---------------------------SHRIKANT SAINI------------------------------------
function GetItemMasterListReducer(state = {
  GetItemMasterListData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemMaster_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemMaster_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemMaster_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemMasterListData: action.payload
      }

    }
    default: return state;
  }

}
function GetSupplierWiseLastItemPriceReducer(state = {
  GetSupplierWiseLastItemPriceData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetSupplierWiseLastItemPrice_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetSupplierWiseLastItemPrice_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetSupplierWiseLastItemPrice_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetSupplierWiseLastItemPriceData: action.payload
      }

    }
    default: return state;
  }
}
function GetItemBatchListReducer(state = {
  GetItemBatchListData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.OPIssueSaleDetailsItemBatchList_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.OPIssueSaleDetailsItemBatchList_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.OPIssueSaleDetailsItemBatchList_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemBatchListData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemBatchListBarcodeReducer(state = {
  GetItemBatchListBarcodeData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.OPIssueSaleDetailsItemBatchListBarcode_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.OPIssueSaleDetailsItemBatchListBarcode_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.OPIssueSaleDetailsItemBatchListBarcode_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemBatchListBarcodeData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemBatchListPayorChangeReducer(state = {
  GetItemBatchListPayorChangeData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.OPIssueSaleDetailsItemBatchListPayorChange_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.OPIssueSaleDetailsItemBatchListPayorChange_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.OPIssueSaleDetailsItemBatchListPayorChange_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemBatchListPayorChangeData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemRxReducer(state = {
  GetItemRxData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemRx_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemRx_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemRx_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemRxData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemBatchSubstitutionListReducer(state = {
  GetItemBatchSubstitutionListData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.OPIssueSaleDetailsItemBatchSubstitutionList_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.OPIssueSaleDetailsItemBatchSubstitutionList_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.OPIssueSaleDetailsItemBatchSubstitutionList_LIST_CLEAR: { return { ...state, fetching: false, fetched: true, GetItemBatchSubstitutionListData: action.payload } }
    case INVM_GETConstants.OPIssueSaleDetailsItemBatchSubstitutionList_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemBatchSubstitutionListData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemsForPickListReducer(state = {
  GetItemsForPickListData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.OPIssueSaleDetailsItemForPickList_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.OPIssueSaleDetailsItemForPickList_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.OPIssueSaleDetailsItemForPickList_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemsForPickListData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemsForEncounterListReducer(state = {
  GetItemsForEncounterListData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.OPIssueSaleDetailsItemForEncounterList_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.OPIssueSaleDetailsItemForEncounterList_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.OPIssueSaleDetailsItemForEncounterList_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemsForEncounterListData: action.payload
      }

    }
    default: return state;
  }

}

function GetPicklistMainReducer(state = {
  GetPicklistMainData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.OPIssueSaleMainGetPickList_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.OPIssueSaleMainGetPickList_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.OPIssueSaleMainGetPickList_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPicklistMainData: action.payload
      }

    }
    default: return state;
  }

}

function GetOPIssueSaleMainReducer(state = {
  GetOPIssueSaleMainData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.OPIssueSaleMain_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.OPIssueSaleMain_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.OPIssueSaleMain_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetOPIssueSaleMainData: action.payload
      }

    }
    default: return state;
  }

}

function GetIPIssueMainReducer(state = {
  GetIPIssueMainData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.IPIssueMain_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.IPIssueMain_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.IPIssueMain_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetIPIssueMainData: action.payload
      }

    }
    default: return state;
  }

}

function GetOPIssueSaleDetailsReducer(state = {
  GetOPIssueSaleDetailsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.OPIssueSaleDetails_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.OPIssueSaleDetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.OPIssueSaleDetails_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetOPIssueSaleDetailsData: action.payload
      }

    }
    default: return state;
  }

}

function GetIPIssueDetailsReducer(state = {
  GetIPIssueDetailsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.IPIssueDetails_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.IPIssueDetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.IPIssueDetails_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetIPIssueDetailsData: action.payload
      }

    }
    default: return state;
  }

}

function GetOPIssueSaleReturnMainReducer(state = {
  GetOPIssueSaleReturnMainData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.OPIssueSaleReturnMain_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.OPIssueSaleReturnMain_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.OPIssueSaleReturnMain_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetOPIssueSaleReturnMainData: action.payload
      }

    }
    default: return state;
  }

}
function GetPicklistDetailsReducer(state = {
  GetPicklistDetailsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.OPIssueSaleDetailsGetPickList_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.OPIssueSaleDetailsGetPickList_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.OPIssueSaleDetailsGetPickList_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPicklistDetailsData: action.payload
      }

    }
    default: return state;
  }

}
function GetOPIssueSaleBatchDetailsReducer(state = {
  GetOPIssueSaleBatchDetailsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.OPIssueSaleBatchDetails_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.OPIssueSaleBatchDetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.OPIssueSaleBatchDetails_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetOPIssueSaleBatchDetailsData: action.payload
      }

    }
    default: return state;
  }

}
function GetEmpStoreAccessDataReducer(state = {
  EmpStoreAccessData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.EmpStoreAccessData_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.EmpStoreAccessData_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.EmpStoreAccessData_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        EmpStoreAccessData: action.payload
      }

    }
    default: return state;
  }

}
function GetDrugItemSettingReducer(state = {
  DrugItemSettingData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.DrugItemSetting_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.DrugItemSetting_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.DrugItemSetting_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        DrugItemSettingData: action.payload
      }

    }
    default: return state;
  }

}
function GetFrequencyMasterReducer(state = {
  FrequencyMasterData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.FrequencyMaster_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.FrequencyMaster_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.FrequencyMaster_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        FrequencyMasterData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemLookSoundSetupReducer(state = {
  ItemLookSoundSetupData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemLookSoundSetup_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemLookSoundSetup_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemLookSoundSetup_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        ItemLookSoundSetupData: action.payload
      }

    }
    default: return state;
  }

}


function GetItemCharactoristicsReducer(state = {
  ItemCharactoristicsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemCharactoristics_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemCharactoristics_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemCharactoristics_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        ItemCharactoristicsData: action.payload
      }

    }
    default: return state;
  }

}

function GetStatusByTypeNatureReducer(state = {
  GetStatusByTypeNatureData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.STATUSBYTYPE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.STATUSBYTYPE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.STATUSBYTYPE_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetStatusByTypeNatureData: action.payload
      }
    }
    default: return state;
  }

}

function GetStatusByTypePOApprovalReducer(state = {
  GetStatusByTypePOApprovalData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.STATUSBYTYPE_POApp_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.STATUSBYTYPE_POApp_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.STATUSBYTYPE_POApp_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetStatusByTypePOApprovalData: action.payload
      }
    }
    default: return state;
  }

}


function GetItemWiseStockReducer(state = {
  GetItemWiseStockData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemWiseStock_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemWiseStock_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemWiseStock_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemWiseStockData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemByNameReducer(state = {
  GetItemByNameData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemByName_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemByName_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemByName_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemByNameData: action.payload
      }

    }
    default: return state;
  }

}

function fetchAdmitPatientReducer(state = {
  fetchAdmitPatientData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.fetchAdmitPatientIPPATIENT_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.fetchAdmitPatientIPPATIENT_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.fetchAdmitPatientIPPATIENT_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        fetchAdmitPatientData: action.payload
      }

    }
    default: return state;
  }

}

function ViewDrugOrderListReducer(state = {
  ViewDrugOrderListData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ViewDrugOrderList_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ViewDrugOrderList_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ViewDrugOrderList_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        ViewDrugOrderListData: action.payload
      }

    }
    default: return state;
  }

}
function GetIPIssueAllocationListReducer(state = {
  GetIPIssueAllocationListData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetIPIssueAllocationList_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetIPIssueAllocationList_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetIPIssueAllocationList_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetIPIssueAllocationListData: action.payload
      }

    }
    default: return state;
  }
}
function GetIPIssueAllocationDeatilsReducer(state = {
  GetIPIssueAllocationDeatilsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetIPIssueAllocationDeatils_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetIPIssueAllocationDeatils_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetIPIssueAllocationDeatils_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetIPIssueAllocationDeatilsData: action.payload
      }

    }
    default: return state;
  }
}
function GetIPIssueListReducer(state = {
  GetIPIssueListData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetIPIssueList_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetIPIssueList_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetIPIssueList_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetIPIssueListData: action.payload
      }

    }
    default: return state;
  }
}

function PrescriptionDetailsReducer(state = {
  PrescriptionDetailsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.PrescriptionDetailsIPPATIENT_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.PrescriptionDetailsIPPATIENT_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.PrescriptionDetailsIPPATIENT_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        PrescriptionDetailsData: action.payload
      }

    }
    default: return state;
  }

}
function GetVitalSignReducer(state = {
  VitalSignData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.VitalSign_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.VitalSign_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.VitalSign_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        VitalSignData: action.payload
      }

    }
    default: return state;
  }

}

function GetVitalMappinReducer(state = {
  VitalMappingData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.VitalMapping_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.VitalMapping_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.VitalMapping_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        VitalMappingData: action.payload
      }
    }
    default: return state;
  }
}

function GetEmpListReducer(state = {
  GetEmpListData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetEmpList_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetEmpList_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetEmpList_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetEmpListData: action.payload
      }

    }
    default: return state;
  }

}

function GetIPIssueReducer(state = {
  GetIPIssueData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetIPIssueDataRxWardAcknowledge_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetIPIssueDataRxWardAcknowledge_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetIPIssueDataRxWardAcknowledge_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetIPIssueData: action.payload
      }

    }
    default: return state;
  }

}

function GetSTKADJSearchReducer(state = {
  GetSTKADJSearchData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.STKADJSearch_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.STKADJSearch_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.STKADJSearch_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetSTKADJSearchData: action.payload
      }

    }
    default: return state;
  }

}

function GetSTKADJMainReducer(state = {
  GetSTKADJMainData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.STKADJMain_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.STKADJMain_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.STKADJMain_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetSTKADJMainData: action.payload
      }

    }
    default: return state;
  }

}

function GetBatchItemWithFacilityReducer(state = {
  GetBatchItemWithFacilityData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.BatchNo_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.BatchNo_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.BatchNo_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetBatchItemWithFacilityData: action.payload
      }

    }
    default: return state;
  }

}

function BatchDetailsReducer(state = {
  BatchDetailsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.BatchDetails_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.BatchDetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.BatchDetails_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        BatchDetailsData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemRouteMappingReducer(state = {
  GetItemRouteData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemRouteMapping_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemRouteMapping_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemRouteMapping_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemRouteData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemRouteMasterReducer(state = {
  GetItemRouteMasterData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemRouteMaster_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemRouteMaster_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemRouteMaster_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemRouteMasterData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemRouteFormMasterReducer(state = {
  GetItemRouteFormMasterData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemRouteFormMaster_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemRouteFormMaster_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemRouteFormMaster_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemRouteFormMasterData: action.payload
      }

    }
    default: return state;
  }

}
//-----------------------------------------------------------------------------
function GetItemDiscountSetupReducer(state = {
  GetItemDiscountSetupData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemDisc_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemDisc_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemDisc_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemDiscountSetupData: action.payload
      }

    }
    default: return state;
  }

}
function GetCompanyNonDiscountableItemsReducer(state = {
  GetCompanyNonDiscountableItemsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.CompanyNonDiscountable_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.CompanyNonDiscountable_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.CompanyNonDiscountable_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetCompanyNonDiscountableItemsData: action.payload
      }

    }
    default: return state;
  }

}
function GetCompanyItemExclusionReducer(state = {
  GetCompanyItemExclusionData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.CompanyItemExclusion_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.CompanyItemExclusion_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.CompanyItemExclusion_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetCompanyItemExclusionData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemWiseBatchesDetailsReducer(state = {
  GetItemWiseBatchesDetailsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemwiseBatchesDetails_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemwiseBatchesDetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemwiseBatchesDetails_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemWiseBatchesDetailsData: action.payload
      }

    }
    default: return state;
  }

}


function GetItemControlledPriceSetupReducer(state = {
  GetItemControlledPriceSetupData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ItemControlledPrice_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ItemControlledPrice_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ItemControlledPrice_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemControlledPriceSetupData: action.payload
      }

    }
    default: return state;
  }

}
function GetInventoryReportsReducer(state = {
  GetInventoryReportsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetInventoryReports_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetInventoryReports_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetInventoryReports_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetInventoryReportsData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemNameDisplaySetupReducer(state = {
  GetItemNameDisplaySetupData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemNameDisplaySetup_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemNameDisplaySetup_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemNameDisplaySetup_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemNameDisplaySetupData: action.payload
      }

    }
    default: return state;
  }

}
function SearchOPInvoiceDetailDataReducer(state = {
  SearchOPInvoiceDetailData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.OPSEARCHINVOICEData_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.OPSEARCHINVOICEData_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.OPSEARCHINVOICEData_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        SearchOPInvoiceDetailData: action.payload
      }
    }
    default: return state;
  }
}

function GetItemWiseDiscountSetupReducer(state = {
  GetItemWiseDiscountSetupData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemWiseDiscountSetup_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemWiseDiscountSetup_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemWiseDiscountSetup_LIST_SUCCESS: {
      return {
        ...state, error: null,
        fetching: false,
        fetched: true,
        GetItemWiseDiscountSetupData: action.payload
      }
    }
    default: return state;
  }

}

function GetPayorItemDiscountReducer(state = {
  GetPayorItemDiscountData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetPayorItemDiscount_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetPayorItemDiscount_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetPayorItemDiscount_LIST_SUCCESS: {
      return {
        ...state, error: null,
        fetching: false,
        fetched: true,
        GetPayorItemDiscountData: action.payload
      }
    }
    default: return state;
  }

}

function GetSlabPayorDiscountReducer(state = {
  GetSlabPayorDiscountData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetSlabPayorDiscount_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetSlabPayorDiscount_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetSlabPayorDiscount_LIST_SUCCESS: {
      return {
        ...state, error: null,
        fetching: false,
        fetched: true,
        GetSlabPayorDiscountData: action.payload
      }
    }
    default: return state;
  }

}

function GetFilterCategoryDataReducer(state = {
  GetFilterCategoryData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetFilterCategoryData_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetFilterCategoryData_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetFilterCategoryData_LIST_SUCCESS: {
      return {
        ...state, error: null,
        fetching: false,
        fetched: true,
        GetFilterCategoryData: action.payload
      }
    }
    default: return state;
  }

}

function GetPrescriptionListReducer(state = {
  GetPrescriptionListData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.OPIssueSalePrescriptionList_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.OPIssueSalePrescriptionList_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.OPIssueSalePrescriptionList_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPrescriptionListData: action.payload
      }
    }
    default: return state;
  }
}
function GetPrescriptionDetailReducer(state = {
  GetPrescriptionDetailData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.OPIssueSalePrescriptionDetail_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.OPIssueSalePrescriptionDetail_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.OPIssueSalePrescriptionDetail_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPrescriptionDetailData: action.payload
      }
    }
    default: return state;
  }
}
function GetPrescriptionViewReducer(state = {
  GetPrescriptionViewData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetPrescriptionViewData_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetPrescriptionViewData_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetPrescriptionViewData_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetPrescriptionViewData: action.payload
      }
    }
    default: return state;
  }
}

function GetInventoryItemNameSetupReducer(state = {
  GetItemNameSetupData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemNameSetup_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemNameSetup_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemNameSetup_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemNameSetupData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemCommonMasterReducer(state = {
  GetItemCommonMasterData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemCommonMaster_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemCommonMaster_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemCommonMaster_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemCommonMasterData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemBrandMasterReducer(state = {
  GetItemBrandMasterData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemBrandMaster_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemBrandMaster_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemBrandMaster_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemBrandMasterData: action.payload
      }

    }
    default: return state;
  }
}

function GetItemQueryTypeReducer(state = {
  GetItemQueryTypeData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetSearchItemQueryType_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetSearchItemQueryType_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetSearchItemQueryType_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemQueryTypeData: action.payload
      }

    }
    default: return state;
  }
}

function GetItemGenericStockReducer(state = {
  GetItemGenericStockData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetSearchItemGenericStock_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetSearchItemGenericStock_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetSearchItemGenericStock_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemGenericStockData: action.payload
      }

    }
    default: return state;
  }
}

function GetReusableItemsUsageReducer(state = {
  GetReusableItemsUsageData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ReusableItemUsage_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ReusableItemUsage_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ReusableItemUsage_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetReusableItemsUsageData: action.payload
      }

    }
    default: return state;
  }

}

function GetBounceItemsReducer(state = {
  GetBounceItemsData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.BounceItems_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.BounceItems_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.BounceItems_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetBounceItemsData: action.payload
      }

    }
    default: return state;
  }

}


function GetStoreReportMappingReducer(state = {
  GetStoreReportMappingdata: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetStore_List_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetStore_List_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetStore_List_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetStoreReportMappingdata: action.payload
      }

    }
    default: return state;
  }

}

function GetAdminssionStatusListReducer(state = {
  GetAdminssionStatusListData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetAdminssionStatus_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetAdminssionStatus_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetAdminssionStatus_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetAdminssionStatusListData: action.payload
      }

    }
    default: return state;
  }

}

function GetSlabWiseDiscountReducer(state = {
  GetSlabWiseDiscountData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetSlabWiseDiscount_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetSlabWiseDiscount_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetSlabWiseDiscount_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetSlabWiseDiscountData: action.payload
      }

    }
    default: return state;
  }

}
function GetItemListConsumptionPIReducer(state = {
  GetItemListConsumptionPIData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemListConsumptionPI_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemListConsumptionPI_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemListConsumptionPI_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemListConsumptionPIData: action.payload
      }

    }
    default: return state;
  }

}

function GetIPIssueTATReportReducer(state = {
  GetIPIssueTATData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetIPIssueTATReport_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetIPIssueTATReport_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetIPIssueTATReport_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetIPIssueTATData: action.payload
      }

    }
    default: return state;
  }

}

function GetItemSearchReducer(state = {
  GetItemSearchData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetItemSearch_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetItemSearch_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetItemSearch_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetItemSearchData: action.payload
      }

    }
    default: return state;
  }
}

function GetReportFacilityMappingDataReducer(state = {
  ReportFacilityMappingData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.ReportFacilityMappingData_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.ReportFacilityMappingData_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.ReportFacilityMappingData_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        ReportFacilityMappingData: action.payload
      }
    }
    default: return state;
  }
}
function GetGeneralBusinessPostingGroupReducer(state = {
  GetGeneralBusinessPostingGroupData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GeneralBusinessPostingGroupData_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GeneralBusinessPostingGroupData_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GeneralBusinessPostingGroupData_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetGeneralBusinessPostingGroupData: action.payload
      }

    }
    default: return state;
  }
}
function GetVendorPostingGroupDataReducer(state = {
  GetVendorPostingGroupDataData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.VendorPostingGroupData_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.VendorPostingGroupData_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.VendorPostingGroupData_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetVendorPostingGroupDataData: action.payload
      }

    }
    default: return state;
  }
}
function GetStoreSubStoreMappingReducer(state = {
  GetStoreSubStoreMapping: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetStoreSubMappingList_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetStoreSubMappingList_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetStoreSubMappingList_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetStoreSubStoreMapping: action.payload
      }

    }
    default: return state;
  }
}

function GetStoreSubMappingReducer(state = {
  GetStoreSubMappingData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case INVM_GETConstants.GetStoreSubMapping_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case INVM_GETConstants.GetStoreSubMapping_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case INVM_GETConstants.GetStoreSubMapping_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        GetStoreSubMappingData: action.payload
      }

    }
    default: return state;
  }
}

   
export {
  GetStoreUserAccessAuditReducer,
  GetItemCategoryReducer, GetCategoryNatureReducer, GetVaccinationReducer, GetEmpUpdateTimeForExportCSVReducer, GetStatusByTypeReducer, GetItemSubCategoryReducer, GetChargeMasterListReducer, GetChargeSetupReducer, GetCIMSDataReducer,
  GetChargesReducer, GetUnitMasterReducer, GetItemUnitMasterReducer, GetHSNGroupReducer, GetHSNCodeReducer, GetItemMasterReducer,
  GetItemChargesReducer, GetItemAttributesReducer, GetItemwithItemUnitMappingReducer, GetChargeTemplateMainReducer, GetChargeTemplateDetailsReducer,
  GetChargeTemplatesReducer, GetChargeTemplateListReducer, GetItemChargesByChargeTemplateReducer, GetItemUnitsByIssueUnitReducer, GetTherapeuticCategoryReducer,
  GetTherapeuticSubCategoryReducer, GetGenericMasterReducer, GetItemGenericMappingReducer, GetItemFormMasterReducer, GetItemFacilityMappingReducer,
  GetItemSubCategoryByCategoryReducer, GetTherapeuticSubCategoryByCategoryReducer, GetHSNCodeByHSNGroupReducer,
  GetItemAttributesOnlyReducer, GetItemChargesOnlyReducer, GetItemWithItemUnitsOnlyReducer, GetItemGenericOnlyReducer, GetItemFacilityOnlyReducer,
  GetItemCountByItemSubCategoryReducer, GetNewItemReuestReducer,
  GetGRNMainReducer, GetGRNDataReducer, GetGRNSearchReducer, GetGRNDetailsReducer, GetGRNBatchDetailsReducer,
  GetPOMainReducer, GetPODataReducer, WorkOrderDataReducer, GetWorkOrderSearchReducer, GetPOSearchReducer, GetPODetailsReducer, GetPOItemChargesReducer, GetChargeTemplateIDReducer, GetPODocumnetChargesReducer, GetWorkOrderChargesReducer,
  GetItemByAttributeReducer,
  GetReusableItemReducer, GetReusableItemsReducer, GetReusableItemChargesReducer, GetChargesByItemReducer, GetGRNItemChargesReducer,
  GetChargesCalculationReducer, GetGRNDocumnetChargesReducer, GetLast5PurchasesReducer, GetStoresByTransTypeReducer,
  GetItemsBySupplierReducer, GetSuppliersByFacilityReducer, GetUserStoreAccessRightsReducer, GetItemListByDescReducer, GetItemPurchaseUnitReducer,
  GetPrescriptionUnitReducer, GetAffiliatedStoreReducer, GetSupplierTermsConditionsReducer, GetWOSupplierTermsConditionsReducer, GetPOTimeLineReducer, GetOPReturnTimeLineReducer, GetItemWiseBatchesReducer,
  GetPurchaseIndentforPOReducer, GetLastPurchasesReducer,
  GetIPIssueStoreListforIPReturnReducer, GetIPReturnMainReducer, GetIPReturnDataReducer, GetIPReturnSearchReducer, GetStoreWiseItemListforIPReturnReducer, GetStoreWiseIPIssuedItemsforIPReturnReducer, GetIPReturnTimeLineReducer,
  GetChargeTemplateIdByCodeReducer, GetItemPriceReducer, GetStatusByTypeSpecial_IndentReducer,
  GetConsignmentMainReducer, GetConsignmentDataReducer, GetConsignmentSearchReducer, GetConsignmentDetailsReducer, GetConsignmentBatchDetailsReducer, GetConsignmentItemChargesReducer,
  GetConsignmentReceiveMainReducer, GetConsignmentReceiveDataReducer, GetConsignmentReceiveSearchReducer, GetConsignmentReceiveDetailsReducer, GetConsignmentReceiveBatchDetailsReducer, GetConsignmentReceiveItemChargesReducer,
  GetStoresByStoreTypeCodeReducer, GetRxOrderDetailsReducer, GetConsignmentAllocationSearchReducer, GetConsignmentReturnSearchReducer, GetPOApprovalListByFacilityReducer,

  GetPOApprovalSetupReducer, GetSupplierDetailReducer, GetSupplierTermsConditiontReducer,
  GetTermsConditionReducer, GetSupplierItemMappingReducer, GetSupplierFacilityMappingReducer, GetSupplierLoginDetailReducer, GetStoreReducer, GetStoreTransactionTypeReducer
  , GetStoreItemMappingReducer, GetStoreROLSetupReducer, GetStoreIndentAffilliationReducer, GetStoreAutoIndentFrequency, GetBinLocationReducer, GetStoreAllTransactionTypeReducer
  , GetStoreSubCategoryOrItemMappingReducer, GetFinancialYearReducer, GetStoreAllSubItemCategoryAndItemMappingReducer, GetAllItemChargeTemplateReducer,
  GetItemSalePriceSetupReducer, GetStoreItemAutoConsumptionMappingReducer,
  GetKITMasterReducer, GetKITItemMappingReducer, GetDepartmentIndentDetailReducer, GetItemForDepartmentIndentReducer, GetDepartmentIndentMainReducer, GetDepartmentIndentTimeLineReducer
  , GetItemMasterBySubCatReducer, GetStoreMappedWithDocumentSetupReducer, GetDepartmentIndentApprovalDetailsReducer, GetItemSubstitutionAndBatchSelectionReducer, GetIndentForIssueReducer, GetIndentForIssuePicklistPrintReducer
  , GetBatchByBarcodeReducer, GetDepartmentIssueReducer, GetDepartmentReceiveReducer, GetDepartmentReturnReducer, GetDepartmentReturnSearchReducer
  , GetOPIssueSaleMainReducer, GetOPIssueSaleDetailsReducer, GetOPIssueSaleBatchDetailsReducer, GetItemMasterListReducer, GetItemBatchListReducer, GetItemBatchListPayorChangeReducer, GetItemBatchListBarcodeReducer
  , GetItemForDepartmentReturnReducer, GetItemListROLReducer, GetItemListConsumptionReducer
  , GetEmpStoreAccessDataReducer, GetPurchaseIndentMainReducer, GetPurchaseIndentDetailsReducer, GetItemDataReducer, getPurchaseIndentAgainstIndentReducer, GetPurchaseIndentTimeLineReducer
  , GetDepartmentConsumptionDetailReducer, GetAllDepartmentConsumptionReducer, GetKitTemplateItemWithBatchReducer
  , GetDrugItemSettingReducer, GetFrequencyMasterReducer
  , GetItemBatchSubstitutionListReducer, GetItemsForPickListReducer, GetPicklistMainReducer, GetPicklistDetailsReducer, GetEMPStoreReducer, GetEMPStoreReportMappedReducer
  , GetItemsForEncounterListReducer, GetDepartmentReceiveSearchPopupReducer, GetOPIssueSaleReturnMainReducer
  , GetItemLookSoundSetupReducer, GetItemCharactoristicsReducer, GetStatusByTypeNatureReducer, GetStatusByTypePOApprovalReducer
  , GetItemWiseStockReducer, GetItemByNameReducer, fetchAdmitPatientReducer, GetIPIssueAllocationReducer
  , GetSTKADJSearchReducer, GetSTKADJMainReducer, GetItemRxReducer, GetBatchItemWithFacilityReducer, BatchDetailsReducer
  , GetItemRouteMappingReducer, GetIPIssueReducer, GetItemRouteMasterReducer, GetItemRouteFormMasterReducer, GetItemListforSupplierReturnReducer
  , GetSupplierReturnTimeLineReducer, GetSupplierReturnDetailsReducer, GetSupplierReturnSearchReducer, GetItemSelectionReducer
  , GetOpeningAdjustmentTimeLineReducer, GetDepartmentIndentAllocationReducer
  , GetItemDiscountSetupReducer, GetCompanyNonDiscountableItemsReducer, GetCompanyItemExclusionReducer, GetItemWiseBatchesDetailsReducer
  , GetSupplierReturnDetailsForGRNReducer, GetItemControlledPriceSetupReducer
  , GetItemsForQuotationReducer, GetQuotationSearchReducer, GetQuotationReducer, QuotationItemChargesReducer, ViewAllQuotationsItemsReducer
  , GetSupplierWiseLastItemPriceReducer, GetItemForDepartmentReturnByBarCodeReducer, GetItemListforSupplierReturnByBarcodeReducer
  , GetIPIssueMainReducer, GetIPIssueDetailsReducer, PrescriptionDetailsReducer, SearchSaleToPatientReducer, GetItemGenericReducer
  , GetVitalSignReducer, GetInventoryReportsReducer, GetItemByMultipleStoresAsyncReducer, GetManufacturerSupplierMappingReducer
  , GetItemNameDisplaySetupReducer, SendEmailReducer, GetConsignmentItemWithStockReducer, GetStockTransferSearchSearchPopupReducer
  , GetConsignmentAllocationDetailReducer, GetConsignmentIssueDetailReducer, GetConsignmentIssueListReducer, SearchOPInvoiceDetailDataReducer
  , GetPendingIndentListReducer, ItemsByFacilityReducer, ViewDrugOrderListReducer, GetIPIssueAllocationListReducer, GetPendingIndentReceiveReducer
  , GetIPIssueListReducer, GetReportMasterReducer, GetEmpListReducer, GetDepartmentIndentAllocationSearchReducer, GetDepartmentDeliverySearchReducer
  , GetIPIssueAllocationDeatilsReducer, GetPayorItemDiscountReducer, GetFilterCategoryDataReducer, GetPendingIndentListByItemReducer, GetItemListByItemNameReducer
  , GetPrescriptionListReducer, GetPrescriptionDetailReducer, GetItemNameSetupReducer, GetItemStrengthMasterReducer, GetItemEnterpriseLevelAttributesReducer
  , GetItemReferenceSizeMasterReducer, GetItemPackSizeMasterReducer, GetItemUniqueCategoryMasterReducer, GetPrescriptionViewReducer, GetInventoryItemNameSetupReducer
  , GetItemCommonMasterReducer, GetItemBrandMasterReducer, GetItemNamesReducer, GetItemConsumtionReducer, CheckSupplierStateIdReducer, GetItemMasterAuditFacilityMappingReducer
  , GetItemMasterAuditControlledPriceReducer, GetItemMasterAuditItemAttributeReducer, GetItemMasterAuditDoseSettingsReducer, GetItemMasterAuditFrequencyReducer, GetItemMasterAuditCharacteristicsReducer
  , GetItemMasterAuditLabelInstructionReducer, GetItemMasterAuditGenericReducer, GetItemMasterAuditRouteMappingReducer, GetSupplierCategoryReducer, GetStoreCategoryReducer, GetDocumentsListReducer
  , GetItemQueryTypeReducer, GetItemGenericStockReducer, GetItemListReadyToReuseByBatchIdReducer
  , GetReusableItemsUsageReducer, GetBounceItemsReducer, GetStoreReportMappingReducer, GetAdminssionStatusListReducer, GetSlabWiseDiscountReducer
  , GetPOPlannerReducer, GetItemListConsumptionPIReducer, GetRequiredItemListByItemNameReducer, GetSlabPayorDiscountReducer, GetVitalMappinReducer, GetStorefacilityWiseReducer
  , GetIPIssueTATReportReducer, GetStoresByAffiliatedStoreReducer, POGRNReceivedDetailsReducer, GetItemListByItemNameExportReducer, GetItemSearchReducer, GetItemWiseDiscountSetupReducer
  , GetConsignmentItemWithStockByChallanReducer, GetReportFacilityMappingDataReducer, GetGeneralBusinessPostingGroupReducer, GetVendorPostingGroupDataReducer, GetLast5PurchasesConsignmentReducer,
   GetStoreSubStoreMappingReducer,GetStoreSubMappingReducer
  
};
//tony
